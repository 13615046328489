import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from './FooterStatic'
import Header from './HeaderStatic'
import '../styles/all.scss'
import useSiteMetadata from '../components/SiteMetadata'
import { withPrefix } from "gatsby"

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="business.realestate" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Header />
      <div id="loadOverlay" style={{
        backgroundColor: 'white',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: 2000
      }}></div>
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
