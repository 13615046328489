import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Footer from './Footer'

const FooterStatic = ({ data }) => {
  const footer = data.allMarkdownRemark.edges[0].node.frontmatter

  return <Footer
    email={footer.email}
    phone={footer.phone}
    company={footer.company}
  />
}

FooterStatic.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query FooterStaticQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "footer" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                email
                phone
                company
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <FooterStatic data={data} count={count} />}
  />
)