import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = ({ email, phone, company }) => {
  return (
    <footer className="sticky-bottom py-4 bg-dark text-white-50">
      <section id="contacts">
        <Container className="text-center">
          <Row>
            <Col>
              <p>
                <a href={"mailto:" + email.trim()}>{email}</a>
              </p>
              <p>
                <a href={"tel:" + phone.replace(" ", "")}>{phone}</a>
              </p>
              <p>{company}</p>
              <small>Copyright &copy; My Beautiful Milano</small>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
