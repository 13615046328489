import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Header from './Header'

const HeaderStatic = ({ data }) => {
  const header = data.allMarkdownRemark.edges[0].node.frontmatter

  return <Header
    logo={header.logo}
    phone={header.phone}
    navigation={header.navigation}
  />
}

HeaderStatic.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query HeaderStaticQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "header" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                templateKey
                logo {
                  alt
                  width
                  image {
                    childImageSharp {
                      fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                phone
                navigation {
                  text
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <HeaderStatic data={data} count={count} />}
  />
)