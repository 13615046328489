import React, { useState } from 'react'
import { Link } from 'gatsby'
import Image from '../components/Image'
import { Nav, Navbar, NavItem, NavLink, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap'

const Header = ({ logo, phone, navigation }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Navbar color="transparent" light expand="md">
        <NavbarBrand href="/">
          <Image imageInfo={logo} style={{
            position: 'relative',
            height: '100%',
            width: logo.width + 'px',
          }} />
        </NavbarBrand>
        <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {navigation ? navigation.map((item, index) =>
              <NavItem key={index}>
                <NavLink >
                  <Link to={item.url ? item.url.replace('../pages', '') : null}>
                    {item.text}
                  </Link>
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Header
